import Mock from 'mockjs'
import { zzkDate, getMessList, getRegisterList, getRegisterRight, getRegisterDayNum } from './data/home.js'

export const getZzk = req => {
	return zzkDate
}
export const getMess = req => {
	return getMessList
}
export const getRegisterRig = req => {
	return getRegisterRight
}
export const getRegister = req => {
	return getRegisterList
}
export const getRegisterSw = req => {
	return getRegisterDayNum
}

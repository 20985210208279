import instance from './request'

/* 测试接口************************************************** */
// 请求数据
export function getHomeData(data) {
    return instance({
        url: '/index/index?id=' + data,
        method: 'get'
    })
}
export function geturllink(data) {
    return instance({
        url: '/std/common/generate/plat/mini_program/url_link',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}
export function medicalMailLink(data) {
    return instance({
        url: '/std/common/medicalMailLink',
        method: 'post'
    })
}

export function getHomeDataList(data) {
    return instance({
        url: '/index/indexList',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}
/* 测试接口************************************************** */

// 正式接口________________________________________________________________________________________
// 登录获取code
export function getWxAuth(data) {
    return instance({
        url: '/auth/wx',
        method: 'post',
        data: data
    })
}

// 医院简介
export function getHospitalList(data) {
    return instance({
        url: '/bs/hospital/page?orgId=3&hospitalId=' + data,
        method: 'get',
        // data: {
        // 	orgId: '3',
        // 	data
        // }
    })
}
// // 取消
// export function cancel(data) {
// 	return instance({
// 		url: '/std/pay/cancel',
// 		method: 'post',
// 		data,
// 	})
// }

// 报告列表
export function getReportList(data) {
    return instance({
        url: '/bs/report/page',
        method: 'get',
        data: {
            orgId: '3',
            data
        }
    })
}
// 获取职业
export function getChild() {
    return instance({
        url: '/sys/dic/getChild?parentId=100',
        method: 'get',
    })
}
// 取消挂号
export function getReportList22(data) {
    return instance({
        url: '/std/pay/cancel',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}

// 一日清单
export function getInpatientList(data) {
    return instance({
        url: '/bs/inpatient/list',
        method: 'get',
        data: {
            orgId: '3',
            data
        }
    })
}

// 押金充值
export function depositPayment(data) {
    return instance({
        url: '/bs/inpatient/payment',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}
// 预约时间
export function registerTime(data) {
    return instance({
        url: '/bs/register/getAppointmentTime',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}

// 查询出诊医生
export function doctorList(data) {
    return instance({
        url: '/bs/doctor/list',
        method: 'get',
        data: {
            orgId: '3',
            data
        }
    })
}


// 待缴费
export function unpaidList(data) {
    return instance({
        url: '/bs/unpaid/list',
        method: 'get',
        data: {
            orgId: '3',
            data
        }
    })
}

// 待缴费支付
export function unpaidPayment(data) {
    return instance({
        url: '/bs/unpaid/payment',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}

// 患者列表, 新增, 修改
export function patientPage(data) {
    return instance({
        url: '/bs/patient/page',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}
export function patientSave(data) {
    return instance({
        url: '/bs/patient/save',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}
export function patientUpdate(data) {
    return instance({
        url: '/bs/patient/update',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}

// 挂号支付
export function registerPayment(data) {
    return instance({
        url: '/bs/register/payment',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}

// 综合查询
// 药品费用信息
export function queryMedicine(data) {
    return instance({
        url: '/bs/query/queryMedicine?orgId=3',
        method: 'get',
        params: data
    })
}
// 治疗费用信息
export function queryPrice(data) {
    return instance({
        url: '/bs/query/queryPrice?orgId=3',
        method: 'get',
        params: data
    })
}

// 报告
export function getReportPage(data) {
    return instance({
        url: '/bs/report/page',
        method: 'get',
        data: {
            orgId: '3',
            data
        }
    })
}

// 获取密钥
export function getJsSignature(data) {
    return instance({
        url: '/sys/common/getJsSignature',
        method: 'get',
        data: {
            orgId: '3',
            data
        }
    })
}

// 查询地址
export function getAddress(data) {
    return instance({
        url: '/bs/address/select',
        method: 'POST',
        data: {
            orgId: '3',
            data
        }
    })
}

// 新增地址
export function getAddAddress(data) {
    return instance({
        url: '/bs/address/save',
        method: 'POST',
        data: {
            orgId: '3',
            data
        }
    })
}

// 修改地址
export function getUpdateAddress(data) {
    return instance({
        url: '/bs/address/update',
        method: 'POST',
        data: {
            orgId: '3',
            data
        }
    })
}

// 删除地址
export function getDeleteAddress(data) {
    return instance({
        url: '/bs/address/delete',
        method: 'POST',
        data: {
            orgId: '3',
            data
        }
    })
}

// 就诊人列表
export function patientList(data) {
    return instance({
        url: '/std/patient/list',
        method: 'POST',
        datas: {
            orgId: '3',
            data
        }
    })
}
// 新增就诊人
export function patientListAdd(data) {
    return instance({
        url: '/std/patient/add',
        method: 'POST',
        data: {
            orgId: '3',
            data
        }
    })
}

// 就诊人详情
export function patientListDetail(data) {
    return instance({
        url: '/std/patient/detail',
        method: 'POST',
        data: {
            orgId: '3',
            data
        }
    })
}

// 删除
export function patientListDel(data) {
    return instance({
        url: '/std/patient/del',
        method: 'POST',
        data: {
            orgId: '3',
            data
        }
    })
}

// 编辑
export function patientListUpdate(data) {
    return instance({
        url: '/std/patient/update',
        method: 'POST',
        data: {
            orgId: '3',
            data
        }
    })
}

// 字典接口
export function getChildDic(data) {
    return instance({
        url: '/sys/dic/getChild?=parentId' + data,
        method: 'get'
    })
}

// 住院信息
// 住院患者账号信息
export function inpatientAccount(data) {
    return instance({
        url: '/std/inpatient/account',
        method: 'POST',
        data: {
            orgId: '3',
            data
        }
    })
}
// 绑定住院号
export function inpatientNumber(data) {
    return instance({
        url: '/std/inpatient/inpatientNumber',
        method: 'POST',
        data: {
            orgId: '3',
            data
        }
    })
}
// 一日清单
export function inpatientList(data) {
    return instance({
        url: '/std/inpatient/list',
        method: 'POST',
        data: {
            orgId: '3',
            data
        }
    })
}
// 充值押金记录
export function paymentPage(data) {
    return instance({
        url: '/std/inpatient/payment/page',
        method: 'POST',
        data: {
            orgId: '3',
            data
        }
    })
}

// 门诊报告
export function mzReportPage(data) {
    return instance({
        url: '/std/report/page',
        method: 'POST',
        data: {
            orgId: '3',
            data
        }
    })
}

// 科室简介
export function profilePage(data) {
    return instance({
        url: '/bs/dept/getDept?orgId=3&hospitalId=21',
        method: 'get',
        params: data
    })
}

// 医生简介
export function doctorPage(data) {
    return instance({
        url: '/bs/doctor/page?orgId=3&hospitalId=21',
        method: 'get',
        params: data
    })
}

// 消息中心
export function selectMessage(data) {
    return instance({
        url: '/bs/message/selectMessage',
        method: 'post'
    })
}

// 消息详情
export function selectRecord(data) {
    return instance({
        url: '/bs/message/selectRecord',
        method: 'post',
        params: data
    })
}

// 住院选择就诊人
export function lnpatientList(data) {
    return instance({
        url: '/std/inpatient/InpatientList',
        method: 'post',
        params: data
    })
}

// 检验报告结果
export function reportInspectOrder(data) {
    return instance({
        url: '/std/report/inspectOrder',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}
// 微生物报告结果
export function reportMicroorganismOrder(data) {
    return instance({
        url: '/std/report/microorganismOrder',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}

// 检查报告结果
export function reportTestOrder(data) {
    return instance({
        url: '/std/report/testOrder',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}
// 扫描住院号查询
export function patientByNum(data) {
    return instance({
        url: '/std/inpatient/patientByNum',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}

// 登录人获取身份证姓名
export function patientUser(data) {
    return instance({
        url: '/std/patient/user',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}

// 押金充值支付
export function transactionsJsapi(data) {
    return instance({
        url: '/std/pay/transactions/jsapi',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}

// 押金充值状态查询
export function inpatientDetail(data) {
    return instance({
        url: '/std/inpatient/detail',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}

// 押金充值下单
export function inpatientOrder(data) {
    return instance({
        url: '/std/inpatient/order',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}

// 满意度列表
export function reportGetInfo(data) {
    return instance({
        url: '/satisfaction/report/page',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}

// 满意度修改
export function reportGetupdate(data) {
    return instance({
        url: '/satisfaction/report/update',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}

// 满意度详情
export function reportGetPageAnswer(data) {
    return instance({
        url: '/satisfaction/report/pageAnswer',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}

// 满意度立即评价
export function reportGetEvaluation(data) {
    return instance({
        url: '/satisfaction/report/evaluation',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}

// 医保授权用户信息
export function mipUserQuery(data) {
    return instance({
        url: '/mhi/pay/mipUserQuery',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}
// 医保授权险种类型
export function getHumanInfo(data) {
    return instance({
        url: '/mhi/pay/getHumanChsInfo',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}
// 医保授权预结算
export function yBprepay(data) {
    return instance({
        url: '/mhi/pay/prepay',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}
// 医保授权挂号预结算
export function regPrepay(data) {
    return instance({
        url: '/mhi/pay/regPrepay',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}
// 医保下单
export function unifiedOrder(data) {
    return instance({
        url: '/mhi/pay/unifiedOrder',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}
// 名医介绍添加科室筛选
export function deptOfDoctor(data) {
    return instance({
        url: '/bs/dept/deptOfDoctor',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}

// 0元付
export function zeroBuy(data) {
    return instance({
        url: '/std/pay/zeroBuy',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}

// 缴费  取消
export function cancel(data) {
    return instance({
        url: '/std/pay/cancel',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}
// 缴费  退款 cancel exceptionCancel
export function exceptionCancel(data) {
    return instance({
        url: '/std/pay/exceptionCancel',
        method: 'post',
        data: {
            orgId: '3',
            data
        }
    })
}
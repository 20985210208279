import {
    getWxAuth
} from '@/request/api'
import config from '@/utils/config'
/**
 * @param {Number} times 回调函数需要执行的次数
 * @param {Function} callback 回调函数
 */
export const doCustomTimes = (times, callback) => {
    let i = -1
    while (++i < times) {
        callback(i)
    }
}

export function isvalidUsername(str) {
    const valid_map = ['admin', 'editor']
    return valid_map.indexOf(str.trim()) >= 0
}


/**
 * 是否登录
 */
export function isLogin(textval) {
    const token = window.sessionStorage.getItem('token')
    if (!token) {
        return false
    }
    return true
}

/**
 * 点击登录
 */
export function goLogin() {
    sessionStorage.clear()
    let sess = window.sessionStorage.getItem('code')

    // 高平线上
    let appid = config.appid //在上一步你用来注册成测试号的appid（注意：这里要和你登录的微信开发者账号的appid一致，也就是要用测试号来开发）
    let local = config.local //要加http，要加端口号
        // 10.10.10.101:8080

    const getUrlParam = name => {
        //封装方法
        var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)') //构造一个含有目标参数的正则表达式对象
        var r = window.location.search.substr(1).match(reg) //匹配目标参数
        if (r != null) return unescape(r[2])
        return null //返回参数值
    }
    let code = getUrlParam('code')
    if (sess != '' && sess != null) {
        if (window.sessionStorage.getItem('img') == null || window.sessionStorage.getItem('img') == '' || window.sessionStorage.getItem('img') == undefined) {

            Dialog.alert({
                title: '登录提示',
                message: '登录中...',
                showConfirmButton: false,
                width: 190
            })

            window.sessionStorage.setItem('code', code)

            getWxAuth({
                code: code
            }).then(res => {
                window.sessionStorage.setItem('img', res.data.data.head)
                window.sessionStorage.setItem('nickname', res.data.data.name)
                window.sessionStorage.setItem('sex', res.data.data.sex)
                window.sessionStorage.setItem('token', res.data.data.token)
            })
        }
    } else {
        window.location.href =
            'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
            appid +
            '&redirect_uri=' +
            encodeURIComponent(local) +
            '&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect'
    }
}


/* 合法url */
export function validateURL(textval) {
    const urlregex =
        /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
    return urlregex.test(textval)
}

/* 小写字母 */
export function validateLowerCase(str) {
    const reg = /^[a-z]+$/
    return reg.test(str)
}

/* 大写字母 */
export function validateUpperCase(str) {
    const reg = /^[A-Z]+$/
    return reg.test(str)
}

/* 大小写字母 */
export function validatAlphabets(str) {
    const reg = /^[A-Za-z]+$/
    return reg.test(str)
}

/* 邮箱 */
export function validateEmail(email) {
    const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        // /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/
    return re.test(email)
}

/* ip地址 */
export function validateIP(ip) {
    const re =
        /^(?:(?:1[0-9][0-9]\.)|(?:2[0-4][0-9]\.)|(?:25[0-5]\.)|(?:[1-9][0-9]\.)|(?:[0-9]\.)){3}(?:(?:1[0-9][0-9])|(?:2[0-4][0-9])|(?:25[0-5])|(?:[1-9][0-9])|(?:[0-9]))$/

    return re.test(ip)
}

/* 域名 */
export function validateDSN(dsn) {
    const re = /(http:\/\/|https:\/\/|[A-Za-z0-9]+[\-]?[A-Za-z0-9]+\.|[A-Za-z0-9]+\.)((\w|=|\?|\.|\/|&|-)*)$/
    return re.test(dsn)
}

/* 电话号码 */
export function validateTel(tel) {
    const re = /^[1][3,4,5,7,8][0-9]{9}$/
    return re.test(tel)
}

/* 非负整数 */
export function validateNumber(num) {
    const re = /^[1-9]+\d*$/
    return re.test(num)
}

/* qq号 */
export function validateQQ(qq) {
    const re = /^[1-9][0-9]{4,10}$/gim
    return re.test(qq)
}

/* 密码 */
export function validatePassword(pwd) {
    const re =
        /^(?![a-zA-Z]+$)(?![a-z\d]+$)(?![a-z!@#\$%]+$)(?![A-Z\d]+$)(?![A-Z!@#\$%]+$)(?![\d!@#\$%]+$)[a-zA-Z\d!@#\$%]+$/
    return re.test(pwd)
}

// 密码不得包含和用户名完整字符串，大小写变位，形似变换的字符串
export function levenshteinDistance(user, pwd) {
    var LevenshteinDistance
    LevenshteinDistance = {
        str1: null,
        str3: null,
        matrix: null,
        isString(s) {
            return Object.prototype.toString.call(s) === '[object String]'
        },
        isNumber(s) {
            return Object.prototype.toString.call(s) === '[object Number]'
        },
        init(str1, str2) {
            if (!this.isString(str1) || !this.isString(str2)) return

            this.str1 = str1
            this.str2 = str2

            str1.length && str2.length && this.createMatrix(str1.length + 1, str2.length + 1)
            this.matrix && this.initMatrix()

            return this
        },
        get() {
            return 1 - this.getDistance() / Math.max(this.str1.length, this.str2.length)
        },
        // 计算编辑距离
        getDistance: function() {
            var len1 = this.str1.length
            var len2 = this.str2.length

            if (!len1 || !len2) return Math.max(len1, len2)

            var str1 = this.str1.split('')
            var str2 = this.str2.split('')

            var i = 0
            var j = 0
            var temp = 0
            while (i++ < len1) {
                j = 0
                while (j++ < len2) {
                    temp = str1[i - 1] === str2[j - 1] ? 0 : 1
                    this.matrix[i][j] = Math.min(
                        this.matrix[i - 1][j] + 1,
                        this.matrix[i][j - 1] + 1,
                        this.matrix[i - 1][j - 1] + temp
                    )
                }
            }
            return this.matrix[i - 1][j - 1]
        },
        /*
         * 初始化矩阵
         * 为第一行、第一列赋值
         */
        initMatrix: function() {
            var cols = this.matrix[0].length
            var rows = this.matrix.length
            var l = Math.max(cols, rows)
            while (l--) {
                cols - 1 >= l && (this.matrix[0][l] = l)
                rows - 1 >= l && (this.matrix[l][0] = l)
            }
        },
        /*
         * 创建矩阵
         * n:行
         * m:列
         */
        createMatrix: function(n, m) {
            if (!this.isNumber(n) || !this.isNumber(m) || n < 1 || m < 1) return

            this.matrix = new Array(n)
            var i = 0
            while (i < n) this.matrix[i++] = new Array(m)
        }
    }
    return LevenshteinDistance.init(pwd, user).get() > 0.5
}

// 密码不得包含和用户名完整字符串，大小写变位，形似变换的字符串
export function similarityString(user, pwd) {
    // 不得包含和用户名完整字符串，大小写变位的字符串
    if (pwd.indexOf(user) !== -1 || pwd.toLocaleUpperCase().indexOf(user.toLocaleUpperCase()) !== -1) {
        return true
    }
    return false
}

// 密码避免键盘排序
export function keyboardSortPassword(pwd) {
    pwd = pwd.toLocaleLowerCase()
    var i = 0
    var arr = ['1234567890', 'qwertyuiop[]', '|asdfghjklzxcvbnm', '~!@#$%^&*()_+']
    for (i = 0; i < arr.length; i++) {
        if (arr[i].indexOf(pwd) !== -1) {
            break
        }
    }
    if (i < arr.length) {
        return true
    }
    return false
}

// 检测是否为外链
export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
}

// 检测是否为演练展示
export function isExhibit(path) {
    return path.indexOf('/exhibit') > -1
}

/* 检测是否为正整数及是否在规定范围内 */
export function isPositiveInteger(number, superLimit = 9999, lowerLimit = 0) {
    return number <= superLimit && number >= lowerLimit && number.indexOf('.') === -1
}
// 生成日期对象

export function getTimeObj(time) {
    let newdata = new Date(time)
    let year = newdata.getFullYear()
    let month = newdata.getMonth() + 1
    let day = newdata.getDate()
    let hour = newdata.getHours()
    let minute = newdata.getMinutes()
    let second = newdata.getSeconds()
    return {
        year,
        month,
        day,
        hour,
        minute,
        second
    }
}
/**
 * 生成各种格式日期
 * type:0 =>2021-09-12 默认
 * type:1 =>2021-09-12 10:34:12
 * type:2 =>09-12 10:34:12
 * type:3 =>09-12 
 * type:4 =>2021-09 
 * type:5 =>2021年09月12日
 * type:6 =>2021年09月12日 10:34:12
 * type:7 =>09月12日 10:34:12
 * type:8 =>09月12日 
 * type:9 =>2021年09月 
 */
export function filterTime(type = 0, stamp) {
    let str = stamp
    let date = ''
    let dateObj = {
        week: '',
        time: ''
    }
    if (stamp && stamp.indexOf('-') > 0) {
        str = new Date(stamp.replace(/-/g, '/')).getTime()
        date = new Date(str)
    } else {
        date = new Date()
    }
    console.log(date);
    let y = date.getFullYear();
    let m = (date.getMonth() + 1 + '').padStart(2, '0');
    let d = (date.getDate() + '').padStart(2, '0');
    let hh = (date.getHours() + '').padStart(2, '0')
    let mm = (date.getMinutes() + '').padStart(2, '0')
    let ss = (date.getSeconds() + '').padStart(2, '0')
    dateObj.week = getWeekTool(date.getDay())
    switch (type) {
        case 0:
            dateObj.time = `${y}-${m}-${d}`;
            break;
        case 1:
            dateObj.time = `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
            break;
        case 2:
            dateObj.time = `${m}-${d} ${hh}:${mm}:${ss}`;
            break;
        case 3:
            dateObj.time = `${m}-${d}`;
            break;
        case 4:
            dateObj.time = `${y}-${m}`;
            break;
        case 5:
            dateObj.time = `${y}年${m}月${d}日`;
            break;
        case 6:
            dateObj.time = `${y}年${m}月${d}日 ${hh}:${mm}:${ss}`;
            break;
        case 7:
            dateObj.time = `${m}月${d}日 ${hh}:${mm}:${ss}`;
            break;
        case 8:
            dateObj.time = `${m}月${d}日`;
            break;
        case 9:
            dateObj.time = `${y}年${m}月`;
            break;
    }
    return dateObj;
}

/* 获取到AddDayCount天后日期 */
export function GetDateStr(AddDayCount) {
    let dd = new Date();
    dd.setDate(dd.getDate() + AddDayCount); // 获取AddDayCount天后的日期
    let y = dd.getFullYear();
    let m = dd.getMonth() + 1; // 获取当前月份的日期
    let d = dd.getDate();
    m = m > 9 ? m : '0' + m
    d = d > 9 ? d : '0' + d
    return `${y}-${m}-${d}`
}

/* 得到日期 */
export function getDayTool(da) {
    let date = {}
    let dd = new Date()
    let n = da || 0
    dd.setDate(dd.getDate() + n)
    let y = dd.getFullYear()
    let m = dd.getMonth() + 1
    let d = dd.getDate()
    m = m < 10 ? '0' + m : m
    d = d < 10 ? '0' + d : d
    date.year = y
    date.day = m + '.' + d
    date.week = getWeekTool(dd.getDay())
    return date
}

/* 得到周几 */
export function getWeekTool(da) {
    let str = '周'
        // let week = new Date().getDay()
    switch (da) {
        case 0:
            str += '日'
            break
        case 1:
            str += '一'
            break
        case 2:
            str += '二'
            break
        case 3:
            str += '三'
            break
        case 4:
            str += '四'
            break
        case 5:
            str += '五'
            break
        case 6:
            str += '六'
            break
    }
    return str
}
// 处理日期格式
export function formate(data, format) {
    data = new Date(data);
    var o = {
        "M+": data.getMonth() + 1, //月
        "d+": data.getDate(), //日
        "h+": data.getHours(), //时
        "m+": data.getMinutes(), //分
        "s+": data.getSeconds(), //秒
    }
    if (/(y+)/.test(format)) {
        format = format.replace(RegExp.$1, (data.getFullYear() + "").substr(4 - RegExp.$1.length));
    }
    for (var k in o) {
        if (new RegExp("(" + k + ")").test(format)) {
            format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
        }
    }
    return format;
}

// 删除数组中某一项或几项 正数第一项（arr, 0）, 倒数第一（arr, -1）
export function handleRemoveItem(arr, from, to) {
    let rest = arr.slice((to || from) + 1 || arr.length);
    arr.length = from < 0 ? arr.length + from : from;
    return arr.push.apply(arr, rest);
}

/**
 * 结算状态返回  
 * 10 待支付 
 * 20 支付平台调用支付成功 25 支付平台查询结果成功
 * 30 支付成功 
 * 35 支付失败 40 支付超时
 * 60 his取消成功 65 支付平台调用退款成功 
 * 70 退款成功 
 * 75 退款失败
 * 35&40合并成支付失败状态
 * 20&25合并支付中状态
 * 60&65合并退款中状态
 * @param {*} state 结算状态
 * @returns 
 */
export function handleRegisterState(state) {
    if (state == 10) {
        return '待支付'
    }
    if (state == 20 || state == 25) {
        return '支付中'
    }
    if (state == 30) {
        return '已支付'
    }
    if (state == 35 || state == 40) {
        return '支付失败'
    }
    if (state == 60 || state == 65) {
        return '退款中'
    }
    if (state == 70) {
        return '已取消'
    }
    if (state == 75) {
        return '取消失败'
    }
}
// 获取url k-v对
export function getKeyValue(a) {
    let index = a.split("#")[1];
    let second = index.split('?')[1];
    let c = second.split("&"); // 以&为分割转换成数组
    let data = {};
    for (let i = 0; i < c.length; i++) {
        let d = c[i].split("="); // 以=为分割 => ['a', '1']
        data[d[0]] = d[1]; // 键值对形式保存 => {a: 1}
    }
    return data
}
import Mock from 'mockjs'
import { doCustomTimes } from '../../request/tool'
import { Random } from 'mockjs' // 导出随机函数
// 切换人列表
export const zzkDate = {
	code: 200,
	message: '请求成功',
	list: [
		{
			gx: '爷孙',
			name: '张三',
			num: '23456789987654567123'
		},
		{
			gx: '母子',
			name: '张三一',
			num: '11111111111123456782'
		},
		{
			gx: '父子',
			name: '张三二',
			num: '77777777777777777543'
			// subname: '描述信息'
		}
	]
}
// 消息列表
export const getMessList = {
	code: 200,
	message: '请求成功',
	listTab: [
		{
			title: '系统消息'
		},
		{
			title: '通知公告'
		},
		{
			title: '挂号消息'
		},
		{
			title: '门诊消息'
		},
		{
			title: '住院消息'
		}
	],
	list: [
		{
			name: '系统消息',
			items: [
				{
					name: '温馨提示'
				},
				{
					name: '您有新的消息，请注意查看'
				}
			]
		},
		{
			name: '系统消息',
			items: [
				{
					name: '温馨提示'
				},
				{
					name: '您有新的消息，请注意查看'
				}
			]
		},
		{
			name: '系统消息',
			items: [
				{
					name: '温馨提示'
				},
				{
					name: '您有新的消息，请注意查看'
				}
			]
		}
	],
	listTg: [
		{
			name: '通知公告',
			items: [
				{
					name: '温馨提示'
				},
				{
					name: '您有新的消息，请及时查看'
				}
			]
		},
		{
			name: '通知公告',
			items: [
				{
					name: '温馨提示'
				},
				{
					name: '您有新的消息，请及时查看'
				}
			]
		},
		{
			name: '通知公告',
			items: [
				{
					name: '温馨提示'
				},
				{
					name: '您有新的消息，请及时查看'
				}
			]
		}
	],
	listGh: [
		{
			name: '挂号信息',
			items: [
				{
					name: '温馨提示'
				},
				{
					name: '您有新的挂号消息，请及时查看'
				}
			]
		},
		{
			name: '挂号信息',
			items: [
				{
					name: '温馨提示'
				},
				{
					name: '您有新的挂号消息，请及时查看'
				}
			]
		},
		{
			name: '挂号信息',
			items: [
				{
					name: '温馨提示'
				},
				{
					name: '您有新的挂号消息，请及时查看'
				}
			]
		}
	],
	listMz: [
		{
			name: '门诊信息',
			items: [
				{
					name: '温馨提示'
				},
				{
					name: '您有新的门诊消息，请及时查看'
				}
			]
		},
		{
			name: '门诊信息',
			items: [
				{
					name: '温馨提示'
				},
				{
					name: '您有新的门诊消息，请及时查看'
				}
			]
		},
		{
			name: '门诊信息',
			items: [
				{
					name: '温馨提示'
				},
				{
					name: '您有新的门诊消息，请及时查看'
				}
			]
		}
	],
	listZy: [
		{
			name: '住院信息',
			items: [
				{
					name: '温馨提示'
				},
				{
					name: '您有新的住院消息，请及时查看'
				}
			]
		},
		{
			name: '住院信息',
			items: [
				{
					name: '温馨提示'
				},
				{
					name: '您有新的住院消息，请及时查看'
				}
			]
		},
		{
			name: '住院信息',
			items: [
				{
					name: '温馨提示'
				},
				{
					name: '您有新的住院消息，请及时查看'
				}
			]
		}
	]
}
// 挂号信息
export const getRegisterRight = {
	code: 200,
	message: '请求成功',
	listContent: {
		id: 1,
		name: '内科',
		subCategoryList: [
			{
				id: 1,
				name: '血液内科1',
				wap_banner_url: Random.dataImage()
			},
			{
				id: 2,
				name: '血液内科2',
				wap_banner_url: Random.dataImage()
			},
			{
				id: 3,
				name: '血液内科3',
				wap_banner_url: Random.dataImage()
			},
			{
				id: 4,
				name: '血液内科4',
				wap_banner_url: Random.dataImage()
			},
			{
				id: 5,
				name: '血液内科5',
				wap_banner_url: Random.dataImage()
			},
			{
				id: 6,
				name: '血液内科6',
				wap_banner_url: Random.dataImage()
			},
			{
				id: 7,
				name: '血液内科7',
				wap_banner_url: Random.dataImage()
			}
		]
	},
	listContentWk: {
		id: 2,
		name: '外科',
		subCategoryList: [
			{
				id: 1,
				name: '血液外科',
				wap_banner_url: Random.dataImage()
			},
			{
				id: 2,
				name: '皮肤外科',
				wap_banner_url: Random.dataImage()
			},
			{
				id: 3,
				name: '神经外科',
				wap_banner_url: Random.dataImage()
			}
		]
	},
	listContentJz: {
		id: 3,
		name: '急诊科',
		subCategoryList: [
			{
				id: 1,
				name: '急诊科1',
				wap_banner_url: Random.dataImage()
			},
			{
				id: 2,
				name: '急诊科2',
				wap_banner_url: Random.dataImage()
			},
			{
				id: 3,
				name: '急诊科3',
				wap_banner_url: Random.dataImage()
			},
			{
				id: 4,
				name: '急诊科4',
				wap_banner_url: Random.dataImage()
			}
		]
	},
	listContentFc: {
		id: 4,
		name: '妇产科',
		subCategoryList: [
			{
				id: 1,
				name: '妇产科1',
				wap_banner_url: Random.dataImage()
			},
			{
				id: 2,
				name: '妇产科2',
				wap_banner_url: Random.dataImage()
			},
			{
				id: 3,
				name: '妇产科3',
				wap_banner_url: Random.dataImage()
			}
		]
	},
	listContentSj: {
		id: 5,
		name: '神经内科',
		subCategoryList: [
			{
				id: 1,
				name: '神经内科1',
				wap_banner_url: Random.dataImage()
			},
			{
				id: 2,
				name: '神经内科2',
				wap_banner_url: Random.dataImage()
			}
		]
	},
	listContentYk: {
		id: 6,
		name: '眼科',
		subCategoryList: [
			{
				id: 1,
				name: '眼科1',
				wap_banner_url: Random.dataImage()
			},
			{
				id: 2,
				name: '眼科2',
				wap_banner_url: Random.dataImage()
			},
			{
				id: 3,
				name: '眼科3',
				wap_banner_url: Random.dataImage()
			}
		]
	},
	listContentJs: {
		id: 7,
		name: '精神科',
		subCategoryList: [
			{
				id: 1,
				name: '精神科1',
				wap_banner_url: Random.dataImage()
			},
			{
				id: 2,
				name: '精神科2',
				wap_banner_url: Random.dataImage()
			},
			{
				id: 3,
				name: '精神科3',
				wap_banner_url: Random.dataImage()
			},
			{
				id: 4,
				name: '精神科4',
				wap_banner_url: Random.dataImage()
			},
			{
				id: 5,
				name: '精神科5',
				wap_banner_url: Random.dataImage()
			}
		]
	}
}

export const getRegisterList = {
	code: 200,
	message: '请求成功',
	listContent: {
		id: 1,
		name: '内科',
		subCategoryList: [
			{
				id: 1,
				name: '血液内科',
				wap_banner_url: Random.dataImage()
			}
		]
	},
	list: [
		{
			name: '内科',
			id: 1
		},
		{
			name: '外科',
			id: 2
		},
		{
			name: '急诊科',
			id: 3
		},
		{
			name: '妇产科',
			id: 4
		},
		{
			name: '神经内科',
			id: 5
		},
		{
			name: '眼科',
			id: 6
		},
		{
			name: '精神科',
			id: 7
		}
	]
}

export const getRegisterDayNum = req => {
	let dayNum = []
	doCustomTimes(5, () => {
		dayNum.push(
			Mock.mock({
				type: '体检',
				srcZk: Random.dataImage(),
				name: Random.cname(),
				zc: '主治医师',
				dj: '三甲',
				sc: '专业治疗30年,对各种疾病有深刻认知。专业治疗30年,对各种疾病有深刻认知。',
				content: Random.integer(1, 10),
				money: Random.integer(100, 1000)
			})
		)
	})
	return dayNum
}

export const getRegisterDayNumAfter = req => {
	let dayNum = []
	doCustomTimes(3, () => {
		dayNum.push(
			Mock.mock({
				type: '检查',
				srcZk: Random.dataImage(),
				name: Random.cname(),
				zc: '主治医师',
				sc: '专业治疗30年,对各种疾病有深刻认知。专业治疗30年,对各种疾病有深刻认知。',
				content: Random.integer(1, 10),
				money: Random.integer(100, 1000)
			})
		)
	})
	return dayNum
}

// 选择出诊医生管理
export const getVisitDocter = req => {
	let dayNum = []
	doCustomTimes(3, () => {
		dayNum.push(
			Mock.mock({
				head: Random.dataImage(),
				name: Random.cname(),
				title: '主治医师',
				// dj: '三甲',
				deptId: 0,
				profile: '专业治疗30年,对各种疾病有深刻认知。专业治疗30年,对各种疾病有深刻认知。',
				price: Random.integer(100, 1000)
			})
		)
	})
	return dayNum
}

// 就诊人管理
export const peopleMan = req => {
	let dayNum = []
	doCustomTimes(3, () => {
		dayNum.push(
			Mock.mock({
				'relation|1': ['本人', '爷爷', '爸爸', '奶奶'],
				'sex|1': ['男', '女'],
				number: /^131[23]\d{11}/,
				age: Random.integer(1, 100),
				idCard: /^142[224]\d{10}/,
				name: Random.cname()
			})
		)
	})
	return dayNum
}
// 挂号
export const peopleGh = req => {
	let dayNum = []
	doCustomTimes(10, () => {
		dayNum.push(
			Mock.mock({
				'gx|1': ['本人', '爷爷', '爸爸', '奶奶'],
				'gender|1': ['男', '女'],
				idcard: /^1423[23123132]\d{18}/,
				url: Random.dataImage(),
				name: Random.cname()
			})
		)
	})
	return dayNum
}

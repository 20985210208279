import Vue from 'vue'
import {
    Button, Search, Toast, Swipe, SwipeItem, Grid, GridItem, Card, Icon,
    Tag, DropdownMenu, DropdownItem, Empty, List, Cell, CellGroup, Tabbar, TabbarItem, NavBar,
    Checkbox, CheckboxGroup, GoodsAction, GoodsActionIcon, GoodsActionButton, Sku,
    SubmitBar, SwipeCell, TreeSelect, Image as VanImage, Tab, Tabs, Uploader, Form, Field, Picker,
    Area, Popup, Calendar, Dialog, Badge, Divider, ActionSheet, NoticeBar, CountDown, Col, Row,  Step, Steps,
    RadioGroup, Radio, Switch, NumberKeyboard, Skeleton, AddressList

} from 'vant';

Vue.use(AddressList);
Vue.use(Skeleton);
Vue.use(Button);
Vue.use(Search);
Vue.use(Toast);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Card);
Vue.use(Icon);
Vue.use(Tag);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Empty);
Vue.use(List);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(NavBar);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
Vue.use(Sku);
Vue.use(SubmitBar);
Vue.use(SwipeCell);
Vue.use(TreeSelect);
Vue.use(VanImage);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Uploader);
Vue.use(Form);
Vue.use(Field);
Vue.use(Picker);
Vue.use(Area);
Vue.use(Popup);
Vue.use(Calendar);
Vue.use(Badge);
Vue.use(Dialog);
Vue.use(Divider);
Vue.use(ActionSheet);
Vue.use(NoticeBar);
Vue.use(CountDown);
Vue.use(Col);
Vue.use(Row);
Vue.use(Step);
Vue.use(Steps);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Switch);
Vue.use(NumberKeyboard);

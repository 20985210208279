import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './vant/index'
import 'vant/lib/index.less' // 用于修改vant默认css变量
import 'reset-css'
import './styles/index.less' // 引入全局css样式
import './mock/index'
import VueClipboard from 'vue-clipboard2' // 复制文本组件
import pinyin from 'js-pinyin' // 将汉字转为拼音字母
Vue.config.ignoredElements = ['wx-open-launch-weapp'];
import {
    Lazyload
} from 'vant'
import { Area } from 'vant';

Vue.use(Area);
import storage from './request/sessionstor'
Vue.use(storage)
import {
    Notify,
    IndexBar,
    IndexAnchor,
    Overlay,
    PullRefresh,
    DatetimePicker,
    Loading
} from 'vant'
Vue.use(Lazyload)
Vue.use(Notify)
Vue.use(IndexBar)
Vue.use(IndexAnchor)
Vue.use(Overlay)
Vue.use(PullRefresh)
Vue.use(DatetimePicker)
Vue.use(Loading)
import axios from './request/request'

// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()
// Vue.use(vConsole)


Vue.prototype.$axios = axios

import YW from './request/jyw.min'
Vue.prototype.$YW = YW
Vue.use(VueClipboard)
Vue.use(pinyin)
Vue.config.productionTip = false


import Aes from "@/components/aes.js"
Vue.prototype.Aes = Aes

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
<template>
	<div id="app">
		<router-view></router-view>
		<!-- 给定一个初始位置position，插槽中填写想滑动的部分 -->
		<!-- <ballZk :position="position" >
			<div @click="goHome">返回首页</div>
		</ballZk> -->
		<ballZk v-show="$route.path == '/homeManage' ? false : true" value="首页" @click="goHome" @routeHome="getPath">
		</ballZk>
		<van-tabbar v-model="active" v-if="$route.meta.isShow">
			<van-tabbar-item to="/homeManage">
				<span>首页</span>
				<template #icon="props">
					<img :src="props.active == 0 ? icon.active : icon.inactive" />
				</template>
			</van-tabbar-item>
			<van-tabbar-item to="/message" :dot="this.$store.state.showDotBool">
				<span>消息</span>
				<template #icon="props">
					<img :src="props.active == 1 ? icon1.active : icon1.inactive" />
				</template>
			</van-tabbar-item>
			<van-tabbar-item to="/userManage">
				<span>我的</span>
				<template #icon=" props " >
					<img :src="active == 2 ? icon2.active : icon2.inactive" />
				</template>
			</van-tabbar-item>
		</van-tabbar>
	</div>
</template>
<script>
	import ballZk from './components/common/ball.vue'
	import router from './router';
	import wx from 'weixin-js-sdk'
	export default {
		components: {
			ballZk
		},
		data() {
			return {
				dotBool: false,
				active: 0,
				icon: {
					active: require('../src/assets/newHome/首页1.svg'),
					inactive: require('../src/assets/newHome/首页.svg')
				},
				icon1: {
					active: require('../src/assets/newHome/消息ic.png'),
					inactive: require('../src/assets/newHome/消息icon.png')
				},
				icon2: {
					active: require('../src/assets/newHome/我的.svg'),
					inactive: require('../src/assets/newHome/我的1.svg')
				}
			}
		},
		created() {
			// var vConsole = new VConsole();
			// this.handleReflushPath()
		},
		methods: {
			goHome() {
				this.$router.push({
					path: '/'
				})
			},
			handleReflushPath() {
				let path = window.location.href.split('#')[1]
				if (path == '/userManage' || path == '/OutpatientDepartment' || path == '/jzHistory' || path ==
					'/patientManage' || path == "/systemSet") {
					this.active = 2
				} else if (path == '/message' || path == '/cardChat') {
					this.active = 1
				} else {
					this.active = 0
				}
			},
			getPath(value) {
				if (value) {
					this.active = 0
				}
			}
		}
	}
</script>
<style lang="less">
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		min-height: 100%;
		background-color: #f7f7f7;
	}

	.van-loading {
		position: absolute !important;
		left: 50% !important;
		top: 35% !important;
		z-index: 99999;
	}

	.van-field__control:disabled {
		-webkit-text-fill-color: #111 !important;
	}

	.van-overlay {
		background-color: rgba(0, 0, 0, .6) !important;
	}

	.van-radio__icon--checked .van-icon {
		background-color: #0FBEA1 !important;
		border-color: #0FBEA1 !important;
	}

	.van-checkbox__icon--checked .van-icon {
		background-color: #0FBEA1 !important;
		border-color: #0FBEA1 !important;
	}

	.van-tabbar-item--active {
		color: #0FBEA1 !important;
	}

	.van-tabs__nav--card {
		height: 38px !important;
	}

	.van-tabs--card>.van-tabs__wrap {
		height: 42px !important;
	}

	html {
		font-size: 100px;
		height: 100%;
	}

	body {
		height: 100%;
		font-size: 0.14rem;
	}
</style>
